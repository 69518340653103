@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

* {
  font-family: 'Play', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --width: 50px;
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2);
}

a{
  text-decoration: none;
}

.container{
  background-color: #1a1f38;
  height: 100vh;

}

.header{
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .nav-item{
  color: #35394a;
  text-decoration: none;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 14px 16px;
  font-size: 17px;
  float:left;
  font-weight: 500;
}

.nav-item.active{
  color: yellow;
  background-color: #2c314f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.nav-item:hover{
  color: yellow;
}

.header-edit{
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.header-edit .nav-items{
  color: #777;
  font-size: 17px;
  cursor: pointer;
}

.header-edit .nav-items.active{
  color: yellow;
}


.add-btn{
  display: block;
  align-items: center;
  justify-content: center;
  background-color: #2c314f;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 2px solid yellow;
  position: fixed;
  top: 90px;
  right: 1px;
  transform: translate(-30%, -20%);
}

.add-btn svg{
  width: 45px;
  height: 45px;
}

.upcoming-alarm{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 25px;
  color: #777;
  gap: 5px;
}

.upcoming-alarm p{
  font-size: 17px;
}

.upcoming-alarm .time{
  font-size: 30px;
  color: #fff;
  font-weight: 600;
}

.alarm-page{
  height: 92vh;
  overflow-y: scroll;
}
.alarm-list{
  padding:0px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 60px;
}
.alarm{
  background-color: #2c314f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  border-radius: 10px;
  padding: 0px 10px;
  cursor: pointer;
  text-decoration: none;
  color: #777;
}

.alarm .type{
  display: flex;
  gap: 20px;
}

.alarm p{
  color: #fff;
  font-size: 24px;
}
.alarm ul{
  display: flex;
  list-style: none;
  gap: 10px;
}
.alarm ul li.active{
  color: yellow;
}

input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -9000px;
  top: -9000px;
}

input[type="checkbox"].toggle + label {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type="checkbox"].toggle + label::before {
  content: "";
  width: 2.8em;
  height: 1.5em;
  background-color: #1a1f38;
  border-radius: 1em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"].toggle + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "";
  font-size: 0.7em;
  height: 1em;
  left: .2em;
  width: 1.8em;
  height: 1.8em;
  background-color: hsl(0, 80%, 60%);
  color: white;
  border-radius: 1em;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}


input[type="checkbox"].toggle:checked + label::before {
  background-color: #1a1f38;
}

input[type="checkbox"].toggle:checked + label::after {

  transform: translate(100%,5%);
  background-color: yellow;

}

input[type="checkbox"].toggle:disabled + label {
  color: #5ae708;
}

input[type="checkbox"].toggle:disabled + label::before {
  background-color: #5ae708;
}

input[type="checkbox"].toggle:disabled + label::after {
  background-color: #5ae708;
}


.active-alarm{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30%;
  gap: 20px;
  color:#fff;
  font-size: 50px;
}
.active-alarm .alarm-logo{
  width: 200px;
  height: 200px;
}
.active-alarm .stop-icon{
  cursor: pointer;
  margin-top: 50px;
  background: hsl(0, 88%, 68%);
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 10px;
}

.edit-page{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 25%;
  gap:65px;
}
.edit-page .edit-title{
  font-size: 50px;
  color: #fff;
}

.edit-page .inputs{
  display: flex;
  align-items: center;
  justify-content: center;
  color: yellow;
  text-align: center;
  gap: 25px;
  background-color: #2c314f;
  width: 90%;
  padding: 20px 10px;
  border-radius: 15px;
}

.edit-page .inputs select{
  background-color: #2c314f;
  border: none;
  cursor: pointer;
  font-size: 20px;
  outline: none;
  text-align: center;
  color: yellow;
}

.edit-page .controls{
  background-color: #2c314f;
  width: 90%;
  color: #fff;
  padding: 15px;
  border-radius: 15px;
}

.edit-page .controls .date-controls{
  display: flex;
  align-items: center;
  font-size: 20px;
  list-style: none;
  gap: 15px;
  margin: 10px -5px;
  color: #777;
}

.date-controls .date{
  cursor: pointer;
}

.edit-page .controls .date-controls .date.active{
  color: yellow;
  border: 1px solid yellow;
  border-radius: 50%;
  padding: 4.5px 8.5px;
  text-align: center;
}

.edit-page .controls .alarm-sounds, .alarm-vibration{
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alarm-sound{
  background-color: #eb4747;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 5px 6px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alarm-sound.active{
  background-color: #5ae708;
}

.stopwatch-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60%;
}

.stopwatch-page .timer{
  display: flex;
  font-size: 60px;
  color: #777;
}

.timer p{
  color: yellow;
}

.stopwatch-page .stopwatch-controller{
  margin-top: 25%;
  display: flex;
  gap: 40px;
}

.stopwatch-controller button{
  background-color: #1a1f38;
  color: yellow;
  outline: none;
  cursor: pointer;
}

.stopwatch-controller button.stop{
  padding: 10px 25px;
  border: 1px solid #eb4747;
  color: #eb4747;
  border-radius: 25px;
}

.stopwatch-controller button.reset{
  padding: 10px 25px;
  border: 1px solid yellow;
  border-radius: 25px;
}

.countdown-page{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 40%;
}

.countdown-page .countdown{
  padding-top: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 60px;
  color: #777;
}

.countdown p{
  color: yellow;
}

.countdown-page .countdown-input{
  display: flex;
  /* display: none; */
  gap: 10px;
}

.countdown-input input{
  width: 100px;
  font-size: 50px;
  background-color: #1a1f38;
  border: none;
  color: yellow;
  text-align: right;
}

.countdown-input input::placeholder{
  color: yellow;
  opacity: 1;
}

.countdown-options{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20%;
  gap: 15px;
}

.countdown-options .option{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 17px 20px;
  background-color: #2c314f;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.countdown-options .option span{
  color: #777;
}

.countdown-control{
  margin-top: 10%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.countdown-control button{
  background-color: #1a1f38;
  color: yellow;
  outline: none;
  cursor: pointer;
  padding: 10px 25px;
  border-radius: 25px;
  outline: none;
}

.countdown-control button.stop-countdown{
  border: 1px solid #eb4747;
  color: #eb4747;
}

.countdown-control button.start-countdown, .reset-countdown{
  border: 1px solid yellow;
  color: yellow;
}


.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1f38;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
  
.loading-spinner {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #f7ae65;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.5s linear infinite;
}
  
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}